import React from 'react';
import Button from '../Button';

const NavigationButton = ({ className }) => {
  return (
    <div className={className}>
      <Button>
        Menu
      </Button>
    </div>
  );
};

export default NavigationButton;
