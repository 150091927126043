import React from 'react';
import CartButton from '../components/CartButton';
import Link from '../components/Link';
import NavigationButton from '../components/NavigationButton';
import PotIconLogo from '../components/PotIconLogo';

const Header = () => {
  return (
    <header className="hover:bg-white duration-300 fixed w-full left-0 top-0 right-0 z-20">
      <div className="container-lg flex items-center justify-between h-20">
        <Link to="/" className="flex items-center no-underline">
          <PotIconLogo className="h-12 w-12" />
        </Link>
        <div className="py-2 rounded flex gap-x-8">
          <nav className="hidden md:flex gap-x-8">
            <Link to="/shop/cachepots/" className="typo-body no-underline">
              Кашпо
            </Link>
            <Link to="/shop/candlesticks/" className="typo-body no-underline">
              Свічники
            </Link>
            <Link to="/shop/candles/" className="typo-body no-underline">
              Свічки
            </Link>
            <Link to="/shop/decor/" className="typo-body no-underline">
              Декор
            </Link>
          </nav>
          <CartButton />
        </div>
      </div>
    </header>
  );
};

export default Header;
