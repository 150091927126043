import { motion } from 'framer-motion';
import React from 'react';
import Button from '../Button';
import CartIcon from '../icons/CartIcon';

import { useLayoutActions } from '../../layout/Context';
import { useCartState } from '../../layout/CartContext';

import cartTotal from '../../utils/cartTotal';

const cartTotalVariants = {
  active: {
    x: '-100%',
  },
  inactive: {
    x: '0%',
  },
};

const CartButton = () => {
  const { openCart } = useLayoutActions();
  const { cartItems } = useCartState();

  return (
    <Button onClick={openCart} className="inline-flex items-center typo-body no-underline">
      <span className="inline-flex flex-nowrap h-6 w-6 overflow-hidden">
        <motion.span
          className="h-6 w-6 flex-shrink-0"
          variants={cartTotalVariants}
          initial={false}
          animate={cartTotal(cartItems) ? 'active' : 'inactive'}
        >
          <CartIcon className="h-6 w-6" />
        </motion.span>
        <motion.span
          className="typo-small bg-black text-white rounded-full h-6 w-6 flex-shrink-0"
          variants={cartTotalVariants}
          initial={false}
          animate={cartTotal(cartItems) ? 'active' : 'inactive'}
        >
          {cartTotal(cartItems)}
        </motion.span>
      </span>
      <span className="ml-2">
        Кошик
      </span>
    </Button>
  );
};

export default CartButton;
