exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-checkout-jsx": () => import("./../../../src/pages/checkout.jsx" /* webpackChunkName: "component---src-pages-checkout-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-partners-jsx": () => import("./../../../src/pages/partners.jsx" /* webpackChunkName: "component---src-pages-partners-jsx" */),
  "component---src-pages-shop-cachepots-jsx": () => import("./../../../src/pages/shop/cachepots.jsx" /* webpackChunkName: "component---src-pages-shop-cachepots-jsx" */),
  "component---src-pages-shop-candles-jsx": () => import("./../../../src/pages/shop/candles.jsx" /* webpackChunkName: "component---src-pages-shop-candles-jsx" */),
  "component---src-pages-shop-candlesticks-jsx": () => import("./../../../src/pages/shop/candlesticks.jsx" /* webpackChunkName: "component---src-pages-shop-candlesticks-jsx" */),
  "component---src-pages-shop-decor-jsx": () => import("./../../../src/pages/shop/decor.jsx" /* webpackChunkName: "component---src-pages-shop-decor-jsx" */),
  "component---src-pages-shop-index-jsx": () => import("./../../../src/pages/shop/index.jsx" /* webpackChunkName: "component---src-pages-shop-index-jsx" */),
  "component---src-templates-blog-post-jsx-content-file-path-opt-build-repo-src-content-blog-gift-ideas-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/gift-ideas/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-opt-build-repo-src-content-blog-gift-ideas-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-opt-build-repo-src-content-blog-how-to-make-a-trench-candle-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/how-to-make-a-trench-candle/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-opt-build-repo-src-content-blog-how-to-make-a-trench-candle-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-box-1-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/box-1/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-box-1-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-box-3-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/box-3/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-box-3-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-box-4-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/box-4/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-box-4-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-cachepot-1-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/cachepot-1/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-cachepot-1-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-cachepot-10-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/cachepot-10/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-cachepot-10-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-cachepot-2-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/cachepot-2/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-cachepot-2-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-cachepot-3-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/cachepot-3/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-cachepot-3-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-cachepot-4-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/cachepot-4/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-cachepot-4-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-cachepot-5-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/cachepot-5/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-cachepot-5-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-cachepot-6-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/cachepot-6/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-cachepot-6-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-cachepot-7-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/cachepot-7/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-cachepot-7-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-cachepot-8-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/cachepot-8/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-cachepot-8-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-cachepot-9-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/cachepot-9/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-cachepot-9-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candle-1-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/candle-1/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candle-1-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candle-10-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/candle-10/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candle-10-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candle-10-pink-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/candle-10-pink/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candle-10-pink-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candle-2-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/candle-2/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candle-2-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candle-3-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/candle-3/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candle-3-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candle-4-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/candle-4/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candle-4-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candle-5-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/candle-5/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candle-5-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candle-6-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/candle-6/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candle-6-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candle-7-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/candle-7/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candle-7-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candle-8-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/candle-8/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candle-8-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candle-9-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/candle-9/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candle-9-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candle-9-pink-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/candle-9-pink/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candle-9-pink-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candlestick-4-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/candlestick-4/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candlestick-4-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candlestick-5-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/candlestick-5/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candlestick-5-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candlestick-6-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/candlestick-6/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candlestick-6-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candlestick-7-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/candlestick-7/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candlestick-7-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candlestick-donut-2-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/candlestick-donut-2/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candlestick-donut-2-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candlestick-donut-3-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/candlestick-donut-3/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-candlestick-donut-3-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-decor-cloud-1-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/decor-cloud-1/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-decor-cloud-1-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-decor-wave-1-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/decor-wave-1/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-decor-wave-1-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-decor-wave-2-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/decor-wave-2/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-decor-wave-2-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-decor-wave-3-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/decor-wave-3/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-decor-wave-3-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-incense-stick-holder-1-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/incense-stick-holder-1/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-incense-stick-holder-1-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-rings-holder-1-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/rings-holder-1/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-rings-holder-1-index-md" */),
  "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-shellfish-figurine-1-index-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/src/content/products/shellfish-figurine-1/index.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-opt-build-repo-src-content-products-shellfish-figurine-1-index-md" */)
}

